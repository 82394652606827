<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <filter-province class="mr-2" v-model:value="provinsi" v-can:hide.distributor />
        <filter-distributor
          v-model:value="distributor"
          v-model:propinsi="provinsi"
          class=" mt-2 mr-2"
          label="fullname"
          style="width: 300px;"
        >
        </filter-distributor>

        <a-select
          style="width: 300px;"
          class=" mt-2 mr-2"
          placeholder="Pilih Brand"
          v-model:value="brand"
          :options="[
            { label: 'All', value: null },
            { label: 'SBI', value: 'SBI' },
            { label: 'SMI', value: 'SMI' },
          ]"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <filter-area
          class=" mr-2 mt-2"
          v-model:value="area"
          v-model:provinsi="provinsi"
          v-can:hide.distributor
        />
        <a-date-picker
          style="width: 300px;"
          class=" mt-2 mr-2"
          v-model:value="start_date"
          :disabled-date="disabledStartDate"
          :format="formatDated"
          placeholder="Tanggal Awal"
        />
        <a-date-picker
          style="width: 300px;"
          class=" mt-2 mr-2"
          v-model:value="end_date"
          :disabled-date="disabledEndDate"
          :format="formatDated"
          placeholder="Tanggal Akhir"
        />

        <a-checkbox v-model:checked="checked" @change="onCheckVirtual" class="mr-2 mt-2"
          >Gudang Virtual</a-checkbox
        >
        <a-button class="m-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
        <a-button
          type="primary"
          stylea="width: 200px; background-color: #3acf41; border: none;"
          title="Download Xls"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <e-chart :title="title" vif="!isFetching" :response="response" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineAsyncComponent,
  defineComponent,
  ref,
  reactive,
  inject,
  onMounted,
  computed,
  nextTick,
  toRefs,
  onBeforeMount,
  provide,
} from 'vue'
import Loading from '@/components/Loading'
import apiClient from '@/services/axios'
// import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterPropinsi'
import FilterArea from '@/components/filter/FilterArea'
// import FilterBrands from '@/components/filter/FilterBrand'
import FilterDistributor from '@/components/filter/FilterDistributor'

import { useDisabledDate } from '@/composables/DisabledDate'
import moment from 'moment'
import useUserInfo from '@/composables/useUserInfo'

export default defineComponent({
  components: {
    FilterProvince,
    FilterArea,
    FilterDistributor,
    // FilterBrands,
    EChart: defineAsyncComponent({
      loader: () => import('./EChart.vue' /* webpackChunkName: "eChart" */),
      loadingComponent: Loading,
    }),
  },
  setup() {
    const modal1Visible = ref(false)
    const setModal1Visible = visible => {
      modal1Visible.value = visible
    }
    const data = ref([])
    const searchText = ref('')
    const searchInput = ref(null)
    const current1 = ref(1)
    const q = ref('')
    const virtual = ref(2)
    const checked = ref(true)

    // const regionals = ref([])
    const provinsi = ref([])
    // const kabupaten = ref([])
    // const distributors = ref([])
    const area = ref([])
    // const start_date = ref(null)
    //  const products = ref([])
    // const end_date = ref(null)
    const brand = ref([])
    const distributor = ref([])
    const { isDistributor, vendor_id } = useUserInfo()
    if (isDistributor.value) {
      distributor.value.push(vendor_id.value)
    }

    const {
      start_date,
      end_date,
      disabledStartDate,
      disabledEndDate,
      startDate,
      endDate,
    } = useDisabledDate()
    const formatDated = 'YYYY-MM-DD'
    start_date.value = moment(new Date()).startOf('month')
    end_date.value = moment(new Date()).endOf('month')

    const params = ref({})
    const response = ref()
    const fetchData = () => {
      const _params = {
        area: area.value,
        provinsi: provinsi.value,
        brand: brand.value,
        start_date: startDate.value,
        distributor: distributor.value,
        end_date: endDate.value,
        virtual: virtual.value,
        q: q.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('api/report-oi/grafik-gudang', {
          params: _params,
        })
        .then(({ data }) => {
          response.value = data
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          state.isFetching = false
        })
    }
    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/report-oi/grafik-gudang', {
          params: { ...params.value, _export: 'xls' },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `laporan-grafik-gudang_${moment().format('DDMMYY_HHmm')}.xlsx`,
          )
          document.body.appendChild(link)
          link.click()
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    const state = reactive({
      loading: false,
      isFetching: false,
      isDownloading: false,
    })
    onMounted(() => {
      console.log('set start_data')

      console.log(
        moment(new Date())
          .startOf('month')
          .format('YYYY MMM DD'),
      )
      fetchData()
    })

    const onCheckVirtual = e => {
      checked.value = e.target.checked
      if (e.target.checked) {
        virtual.value = 2
      } else {
        virtual.value = 1
      }
    }

    const search = () => {
      fetchData()
    }

    const handleOk = () => {
      loading.value = true
      setTimeout(() => {
        loading.value = false
        visible.value = false
      }, 2000)
    }

    const handleCancel = () => {
      visible.value = false
    }

    const title = computed(() => {
      return 'Level Stock'
    })

    return {
      modal1Visible,
      setModal1Visible,
      handleOk,
      handleCancel,
      q,
      searchText,
      searchInput,
      data,
      current1,
      area,
      provinsi,
      brand,
      state,
      fetchXlsx,
      fetchData,
      ...toRefs(state),
      start_date,
      end_date,
      search,
      title,
      response,
      disabledStartDate,
      disabledEndDate,
      formatDated,
      moment,
      checked,
      virtual,
      onCheckVirtual,
      distributor,
    }
  },
})
</script>
<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
