<template>
  <a-select
    mode="multiple"
    v-model:value="valueState"
    style="width: 300px"
    placeholder="Pilih Provinsi"
    allow-clear
    not-found-content="Tidak ditemukan"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @blur="() => (findText = null)"
    @select="() => (findText = null)"
    v-bind="$attrs"
    :loading="loading"
    :show-arrow="true"
  >
    <a-select-option v-for="(d, index) in data" :key="index" :value="d.id" :label="d.name">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.name)"></span>
      <!--eslint-enable-->
    </a-select-option>
  </a-select>
</template>

<script>
import { onMounted, ref, watch, toRefs, reactive } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'

export default {
  props: {
    value: {
      default: () => [],
      type: Array,
    },
    regional: {
      default: () => [],
      type: Array,
    },
    alone: [Boolean],
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const data = ref([])
    const findText = ref(null)
    const { regional, alone } = toRefs(props)

    const state = reactive({
      loading: false,
    })

    const fetchData = regional => {
      console.log('fetchData filter province', regional)
      if (!alone && regional.length <= 0) regional = [-1]
      state.loading = true
      apiClient
        .get('/api/filter/wilayah-by-role/1', { params: { region: regional } })
        .then(response => {
          const sorted = response.data
          data.value = sorted.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        })
        .finally(() => {
          state.loading = false
        })
    }

    onMounted(() => {
      fetchData(regional.value)
    })

    watch(regional, (after, before) => {
      emit('update:value', [])
      fetchData(after)
    })

    const onSearch = value => {
      findText.value = value
    }

    const highlight = value => {
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }

    return {
      data,
      valueState: useVModel(props, 'value'),
      findText,
      highlight,
      onSearch,
      ...toRefs(state),
    }
  },
}
</script>

<style></style>
